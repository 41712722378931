'use strict';

window.console || (window.console = {
	log: function log() {}
});
var MITSUI_HC = window.MITSUI_HC || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	MITSUI_HC = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.|192\\.168\\.2\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);

				if ($(this).next('.js-toggleBlock').find('.js-mh-icon').length) {
					$('.js-mh-icon').matchHeight();
				}
				if ($(this).next('.js-toggleBlock').find('.js-mh-txt').length) {
					$('.js-mh-txt').matchHeight();
				}
			});
		},
		tabChange: function tabChange() {
			if ($('.js-tabChange').length) {
				$('.js-tab-content').eq(0).addClass(a);
				$('.js-tabChange').eq(0).addClass(a);
				$('.js-tabChange').each(function (idx, val) {
					$(val).on('click', function () {
						$('.js-tabChange').removeClass(a);
						$(val).addClass(a);
						$('.js-tab-content').removeClass(a);
						$('.js-tab-content').eq(idx).addClass(a);
					});
				});
			}
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (MITSUI_HC.va.window.width < MITSUI_HC.va.device.desktop) {
						pos -= 70;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash.length) {
				$('html, body').css('display', 'none');
				setTimeout(function () {
					$('html, body').css('display', 'block');

					var _hash = '#' + hash.split('#')[1];
					var pos = $(_hash).offset().top;

					if (MITSUI_HC.va.window.width < MITSUI_HC.va.device.desktop) {
						pos -= 70;
					} else {
						pos -= 100;
					}
					$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
				}, 100);
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footerBtm = $('.footer-bottom');
				var footerPos = $footerBtm.offset().top + $footerBtm.height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $footerBtm.height() + thisScroll;
				if (MITSUI_HC.va.window.width <= 1024) {
					pagetopPos += 70;
				} else {
					pagetopPos += 100;
				}

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}

				if (footerPos <= pagetopPos) {
					$pagetop.addClass(a);
				} else {
					$pagetop.removeClass(a);
				}
			});
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		headerMenu: function headerMenu() {
			var $hamburger = $('.header-menuBtn');
			var $nav = $('.header-nav');
			var $close = $('.header-nav-close');
			var flag = false;
			var scrollTopNow = void 0;

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				if ($('body').hasClass('fm-questionpage')) {
					// お問い合わせフォーム対応
					$('body > form').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
				} else {
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
				}
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				if ($('body').hasClass('fm-questionpage')) {
					// お問い合わせフォーム対応
					$('body > form').css({
						position: 'static'
					});
				} else {
					$('.container').css({
						position: 'static'
					});
				}
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};

			// ハンバーガーメニュー
			$hamburger.on('click', function () {
				if (!flag) {
					$nav.addClass(a);
					$hamburger.addClass(a);
					fixedOnContainer();
					flag = true;
				} else {
					$nav.removeClass(a);
					$hamburger.removeClass(a);
					fixedOffContainer();
					flag = false;
				}
			});
			$close.on('click', function () {
				$nav.removeClass(a);
				$hamburger.removeClass(a);
				fixedOffContainer();
				flag = false;
			});

			$(window).on('resize', function (e) {
				var winW = $(window).width();
				if (winW > 1024) {
					fixedOffContainer();
				}
			});

			// 検討リスト カウント
			var reviewStorage = [];
			for (var i in sessionStorage) {
				if (i.indexOf('reviewItem-') !== -1) {
					reviewStorage.push(i);
				}
			}

			$('.header .js-reviewList-length').text(reviewStorage.length);
			if (reviewStorage.length > 0) {
				$('.js-reviewList-link').addClass('is-list');
			}

			// カレント
			$('.js-current').each(function (idx, ele) {
				if ($(ele).attr('href') === '/') {
					if (location.pathname === $(ele).attr('href') || location.pathname === $(ele).attr('href') + 'index.html') {
						$(ele).addClass(a);
					}
				} else {
					if (location.pathname.indexOf($(ele).attr('href')) !== -1) {
						$(ele).addClass(a);
					}
				}
			});

			// お問い合わせフォームへのリンクにパラメータ付与
			if ($('.js-contactForm').length) {
				var $thisDomain = window.location.host;
				var $formDomain = void 0;
				if ($thisDomain === 'www.mitsui-hall-conference.jp') {
					$formDomain = '//form.mitsui-hall-conference.jp';
				} else {
					$formDomain = '//test-form-mitsui-hall-conference.webcas.net';
				}

				$('.js-contactForm').each(function (idx, ele) {
					var $href = $(ele).attr('href');
					if ($href.indexOf('?') !== -1) {
						// すでにパラメータ有
						$(ele).attr('href', $formDomain + $href + '&key_id=key999&checklist=' + reviewStorage.length);
					} else {
						// パラメータ無し
						$(ele).attr('href', $formDomain + $href + '?key_id=key999&checklist=' + reviewStorage.length);
					}
				});
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.tabChange();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.modal();
			_this.headerMenu();

			svg4everybody();
		}
	};

	$(function () {
		return MITSUI_HC.localDecision() ? MITSUI_HC.localLoading() : MITSUI_HC.loadDelayScript();
	});
})(window.jQuery);